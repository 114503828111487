<script>
    import axios from "axios";
    import { closeModal, closeAllModals, openModal, modals } from "svelte-modals";
    import { _ } from "../services/i18n"
    import Swal from "sweetalert2";
    export let isOpen;
    export let model;
  
   
 
  
    

    async function DeleteModal(id) {
   
   var data = JSON.stringify({
     id: id,
   });

   var config = {
     method: "delete",
     url: "https://ws.pdf-studio-api.com/" +"/editique_pdf/delete_modal",
     headers: {
       "Content-Type": "application/json",
       Authorization: "Bearer " + localStorage.getItem("access_token"),
     },
     data: data,
   };

   axios(config)
     .then((res)=>{
       closeModal()
     Swal.fire({
         icon: "success",
         text:  $_('workspace.alertDeleteModel'),
     
       }).then(()=> window.location.reload())
     }
   
          
         )
     .catch(function (error) {
       closeModal()
       console.log(error);
       Swal.fire({
          icon: "error",
          // title: "Oops...",
          text:  $_('workspace.Échec de suppression du modèle'),
        });

     });
 

}

</script>

<!-- {#if isOpen} -->
<div role="dialog" class="modal">
  <div class="contents">
    <div>
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div class="fixed inset-0 z-10 ">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
        
              <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-[url('assets/images/bg.png')]"
              >
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                   
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 ">
                      <h3
                        style="color:#945FAF;font-size:1.3rem;font-weight:500;font-family:Roboto"
                        id="modal-title"
                      >
                      {$_('workspace.supprimerModéle')}
                      </h3>
                      <div class="m-6">
                        <label
                          for="default-input"
                          style="color: #7E7E7E; font-size:1rem;font-weight:500;font-family:Roboto"
                          >{$_('workspace.textSupprimerModéle')}</label
                        >
                       
                      </div>
                     
                <div
                  class="  pb-6 text-center "
                >
                  <button
                    type="button"
                    on:click={closeModal}
                    style="background-color:#7E7E7E; width:8rem; padding-block:0.5rem;color:white;border-radius:0.5rem"
                    >{$_('workspace.non')}</button
                  >
                  <button
                    type="button"
                    style="background-color:#945FAF; width:8rem;padding-block:0.5rem;color:white;border-radius:0.5rem"
                    on:click={DeleteModal(model)}
                    >{$_('workspace.oui')}</button
                  >
                 
                  
                </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- {/if} -->
<style>.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* allow click-through to backdrop */
  pointer-events: none;
}

.contents {
  min-width: 240px;
  border-radius: 6px;
  padding: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1N1cHByZXNzaW9uTW9kYWwuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0Usa0JBQWtCO0VBQ2xCLE1BQU07RUFDTixTQUFTO0VBQ1QsUUFBUTtFQUNSLE9BQU87RUFDUCxrQkFBa0I7RUFDbEIsYUFBYTtFQUNiLHVCQUF1QjtFQUN2QixtQkFBbUI7RUFDbkIsb0NBQW9DO0VBQ3BDLG9CQUFvQjtBQUN0Qjs7QUFFQTtFQUNFLGdCQUFnQjtFQUNoQixrQkFBa0I7RUFDbEIsYUFBYTtFQUNiLGlCQUFpQjtFQUNqQixhQUFhO0VBQ2Isc0JBQXNCO0VBQ3RCLDhCQUE4QjtFQUM5QixvQkFBb0I7QUFDdEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvU3VwcHJlc3Npb25Nb2RhbC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5tb2RhbCB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAwO1xuICBib3R0b206IDA7XG4gIHJpZ2h0OiAwO1xuICBsZWZ0OiAwO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAvKiBhbGxvdyBjbGljay10aHJvdWdoIHRvIGJhY2tkcm9wICovXG4gIHBvaW50ZXItZXZlbnRzOiBub25lO1xufVxuXG4uY29udGVudHMge1xuICBtaW4td2lkdGg6IDI0MHB4O1xuICBib3JkZXItcmFkaXVzOiA2cHg7XG4gIHBhZGRpbmc6IDE2cHg7XG4gIGJhY2tncm91bmQ6IHdoaXRlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIHBvaW50ZXItZXZlbnRzOiBhdXRvO1xufVxuIl19 */</style>
