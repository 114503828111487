<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  export let page;
  const dispatch = createEventDispatcher();
  let canvas;
  let width;
  export let height;
  export let scale
  let clientWidth;
  let mounted;
  function measure() {
    dispatch("measure", {
      scale: canvas.clientWidth / width
    });
  }
  async function render() {
    const _page = await page;
    const context = canvas.getContext("2d");
    const viewport = _page.getViewport({ scale: scale ? scale : 1, rotation: 0 });
    width = viewport.width;
    height = viewport.height;
    await _page.render({
      canvasContext: context,
      viewport
    }).promise;
    measure();
    window.addEventListener("resize", measure);
  }
  onMount(render);
  onDestroy(() => {
    window.removeEventListener("resize", measure);
  });
</script>

<div class="pdfpage">
  <canvas
    bind:this={canvas}
    class="max-w-full"
    style="width: 75vw;"
    {width}
    {height} />
</div>

<style>.pdfpage {
  -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1BERlBhZ2Uuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0QseUZBQXlGO0VBQ2pGLGlGQUFpRjtBQUUxRjs7QUFjQTtFQUNFO0lBRVUsOEJBQThCO0lBQ3RDLFVBQVU7RUFDWjs7RUFDQTtJQUVVLHdCQUF3QjtJQUNoQyxVQUFVO0VBQ1o7QUFDRiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9QREZQYWdlLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAucGRmcGFnZSB7XG5cdC13ZWJraXQtYW5pbWF0aW9uOiBzbGlkZS1pbi1md2QtY2VudGVyIDAuNHMgY3ViaWMtYmV6aWVyKDAuMjUwLCAwLjQ2MCwgMC40NTAsIDAuOTQwKSBib3RoO1xuXHQgICAgICAgIGFuaW1hdGlvbjogc2xpZGUtaW4tZndkLWNlbnRlciAwLjRzIGN1YmljLWJlemllcigwLjI1MCwgMC40NjAsIDAuNDUwLCAwLjk0MCkgYm90aDtcbiAgICAgICAgICBcbn1cblxuIEAtd2Via2l0LWtleWZyYW1lcyBzbGlkZS1pbi1md2QtY2VudGVyIHtcbiAgMCUge1xuICAgIC13ZWJraXQtdHJhbnNmb3JtOiB0cmFuc2xhdGVaKC0xNDAwcHgpO1xuICAgICAgICAgICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVaKC0xNDAwcHgpO1xuICAgIG9wYWNpdHk6IDA7XG4gIH1cbiAgMTAwJSB7XG4gICAgLXdlYmtpdC10cmFuc2Zvcm06IHRyYW5zbGF0ZVooMCk7XG4gICAgICAgICAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVooMCk7XG4gICAgb3BhY2l0eTogMTtcbiAgfVxufVxuQGtleWZyYW1lcyBzbGlkZS1pbi1md2QtY2VudGVyIHtcbiAgMCUge1xuICAgIC13ZWJraXQtdHJhbnNmb3JtOiB0cmFuc2xhdGVaKC0xNDAwcHgpO1xuICAgICAgICAgICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVaKC0xNDAwcHgpO1xuICAgIG9wYWNpdHk6IDA7XG4gIH1cbiAgMTAwJSB7XG4gICAgLXdlYmtpdC10cmFuc2Zvcm06IHRyYW5zbGF0ZVooMCk7XG4gICAgICAgICAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVooMCk7XG4gICAgb3BhY2l0eTogMTtcbiAgfVxufVxuIl19 */</style>